* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0px !important;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #161214;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

.flex {
  display: flex;
  width: 100%;
  align-items: center;
}

.container {
  display: flex;
}

.side {
  display: flex;
  flex-direction: column;
  padding: 40px;
  min-width: 450px;
  max-width: 450px;
  overflow-y: scroll;
  height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.side::-webkit-scrollbar {
  display: none;
}

input {
  display: flex;
  flex: 1;
  width: 100%;
  margin-right: 15px;

  border-radius: 8px;
  outline: none;
  background-color: transparent;
  border: 0px;

  border: 1.5px solid rgba(255, 255, 255, 0.2);
  padding: 10px 12px;
  color: #fff;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}

.result-container {
  height: 100vh;
  position: relative;
  flex: 1;
}

.result {
  display: flex;
  position: absolute;
  inset: 25px;
  background-color: #fff;
  overflow: scroll;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 20px;
}

.m-only {
  display: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  padding: 30px 20px;
  border-radius: 10px;
  font-weight: 500;
  flex-direction: column;
  gap: 20px;
}

.m-only svg {
  width: 30px;
  height: auto;
}

@media only screen and (max-width: 1000px) {
  .m-only {
    display: flex;
  }

  .container {
    flex-direction: column;
  }

  .side {
    max-width: none;
    min-width: 0;
    padding: 30px;
    height: auto;
  }

  .actions {
    flex-direction: column;
  }

  .result-container,
  .result {
    height: 1000px;
    position: relative;
    inset: 0;
    margin: 0px;
    border-radius: 30px 30px 0px 0px;
    width: 100%;
  }
}

.actions {
  position: absolute;
  right: 30px;
  bottom: 30px;
  left: 30px;
  justify-content: flex-end;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}

.actions button,
.actions .hiring {
  font-size: 16px;
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 9999px;
  font-weight: 600;
  padding: 15px 25px;
  cursor: pointer;
  color: #241520;
  text-decoration: none;
  cursor: pointer;
}

.site svg {
  opacity: 0.4;
  margin-bottom: 15px;
  width: 45px;
  height: auto;
  color: #24152050;
  color: #fff;
}

.actions .hiring {
  color: #fff;
  border: 0px;
  background-color: #ff722b;
}

.actions button {
  /* border: 0px; */
  /* background-color: #fff; */
  /* border: 3px solid #fff50; */
  color: #241520;
}

label {
  height: 25px;
  padding: 0px 15px 0px 0px;
  display: flex;
  flex: 0;
  align-items: center;

  font-weight: 600;
  font-size: 14px;
  opacity: 0.5;

  align-items: center;
}

h4,
.flex.margin {
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 18px;
}

.description {
  font-size: 13px;
  margin-bottom: 15px;
  color: #bfc1c1;
  line-height: 150%;
}

.description b {
  color: #fff;
}

.MuiSlider-track,
.MuiSlider-thumb {
  background-color: #fff !important;
}

.MuiSlider-rail {
  background-color: #fff !important;
  opacity: 0.2 !important;
}

/* .MuiSlider-thumb::after {
  opacity: 0;
} */

.MuiSwitch-thumb {
  background-color: rgb(98, 98, 98) !important;
}

.Mui-checked .MuiSwitch-thumb {
  background-color: #fff !important;
}

.MuiSwitch-track {
  background-color: #fff !important;
  opacity: 0.1 !important;
}

.Mui-checked .MuiSwitch-track {
  background-color: #fff !important;
  opacity: 0.3 !important;
}

textarea {
  width: 100%;
  height: 200px;
}
